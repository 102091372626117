import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProSidebarProvider } from "react-pro-sidebar";
import PrivateRoutes from "./PrivateRoutes";
import Component from "../components";
import Register from "../screens/auth.screen/register.auth.screen/register";
import Signin from "../screens/auth.screen/signin.screen/signin";
import NotFound from "../screens/notFound.screen/notFound";
import JankariCategory from "../screens/jankari.screen/category.screen/category";
import Feeds from "../screens/Feeds.screen/feed.screen/Feeds";
import Post from "../screens/jankari.screen/post.screen/post";
import FeedCategoryScreen from "../screens/Feeds.screen/feedCategory.screen/FeedCategory";
import Chat from "../screens/Chat.screen/chat";
import BotScript from "../screens/Chat.screen/BotScript.screen/botScript";
import Users from "../screens/user.screen/User";
import Posts from "../screens/posts.screen/posts";
import StatsScreen from "../screens/dashboard.screen/stats.js";
import AgriStick from "../screens/agriStick.screen/agriStick";
import CropScreen from "../screens/Crops.screen";
import EmployeeScreen from "../screens/employee.Screen/EmployeeScreen";
import SoilTestingRequests from "../screens/soilTesting.screen/requests.screen/SoilTestingRequest";
import ReportScreen from "../screens/report.screen/index.js";
import UserIntrestScreen from "../screens/userIntrest.screen/index.js";
export default function RootNavigator() {
  return (
    <ProSidebarProvider>
      <Routes>
        <Route path="/" element={<Signin />} />
        <Route path="/register" element={<Register />} />
        <Route path="/post/:postId" element={<Posts />} />
        <Route element={<PrivateRoutes />}>
          <Route element={<Component.SideBar />}>
            <Route path="/jankari/category" element={<JankariCategory />} />
            <Route path="/jankari/post" element={<Post />} />
            <Route path="/stats" element={<StatsScreen />} />
            <Route path="/home" element={<Feeds />} />
            <Route path="/feedCategory" element={<FeedCategoryScreen />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/botScript" element={<BotScript />} />
            <Route path="/users" element={<Users />} />
            <Route path="/agriStick" element={<AgriStick />} />
            <Route path="/crops" element={<CropScreen />} />
            <Route path="/employee" element={<EmployeeScreen />} />
            <Route path="/report" element={<ReportScreen />} />
            <Route path="/intrest" element={<UserIntrestScreen />} />
            <Route
              path="/soilTestingDashboard/requests"
              element={<SoilTestingRequests />}
            />
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ProSidebarProvider>
  );
}
